import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTheme } from '@emotion/react';
import {  FaPlayCircle } from 'react-icons/fa'; // Importing an icon from react-icons
import { RiVoiceprintFill } from "react-icons/ri";
import { PageContainer, FlexContainer, DropzoneContainer, UploadedFileInfo, VideoPreviewContainer, VideoPlayer ,Container, LeftBlock, RightBlock, FileInfo, FileIcon, FileName, ChangeButton, SubHeadingContainer, SubHeadingBlock, SubHeading, DetectedContainer, DetectedFace, Square, Arrow, Circle, ChooseImageButton, VoiceContainer, VoiceRow, PlayIcon, VoiceName, TagWrapper, VoiceTag, SelectVoiceButton, UploadAudioButton, NextButton, SubHeadingBlockVoice, CircleDetectedFace } from '../styles/uploadVideoStyle';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { PulseLoader } from 'react-spinners';
import {  FaPauseCircle } from 'react-icons/fa'; // Add Pause Icon
import Config from '../BaseUrl';
import ProgressiveLoader from '../components/common/ProgressiveLoader';

const VideoUploadPage: React.FC = () => {
 
  const [voices, setVoices] = useState<Voice[]>([]);
  const [frameimages, setframeimages] = useState<extractimages[]>([]);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null); // State to manage the uploaded file
  const [previewUrl, setPreviewUrl] = useState<string | null>(null); // State to manage the preview URL
  const [targetfile, settargetfile] = useState<string | null>(null); // State to manage the preview URL
  const [showStep1, setShowStep1] = useState(true); // State to manage visibility of Step 1
  const [showStep2, setShowStep2] = useState(false); // State to manage visibility of Step 2
  const [showStep3, setShowStep3] = useState(false); // State to manage visibility of Step 3
  const [key, setKey] = useState(0); // update the key to force re-render of video player
  const [selectedImages, setSelectedImages] = useState<{ [key: number]: string }>({}); // State to manage selected images
  const [activeFaceIndex, setActiveFaceIndex] = useState<number | null>(null); // State to manage the active face index
  const [imageDropzoneOpen, setImageDropzoneOpen] = useState(false); // State to manage the visibility of the image dropzone Step 2
  const navigate = useNavigate(); // Used to navigate to the new page
  const [uniqueName, setuniqueName] = useState<string>("");
  const [userFolderPath, setuserFolderPath] =useState<string>("");
  const [baseDirectory, setbaseDirectory] =useState<string>("");
  const [loading, setLoading] = useState(false); // State for tracking loading
  const [Aerror, setAError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isface, setisface] = useState<boolean | null>(null);

 // const audioRef = useRef(null); // Ref for the audio element
  const audioRef = useRef<HTMLAudioElement | null>(null); // Ref to the audio element
  const [currentlyPlayingIndex, setCurrentlyPlayingIndex] = useState<number | null>(null); // Track currently playing voice index
  const history = useNavigate();

  interface Voice {
    id: string; // or number, depending on your ID type
    name: string;
    audioUrl:string;  
    }
    interface extractimages {
      path: string; // or number, depending on your ID type
      frameNumber: number;
  }
  //Step 3
 
  const [selectedVoiceIndex, setSelectedVoiceIndex] = useState<number | null>(null);
  
  const [selectedVoice, setSelectedVoice] = useState<string>("");
  const [uploadedAudio, setUploadedAudio] = useState<File | null>(null);  // State to manage the uploaded audio

  
  const VideoToImg = async (file: File) => {
     try {
      setisface(false);
      const formData = new FormData();
      formData.append('videoFile', file); // Assuming `file` is available in scope
  

      if (file.size > 2 * 1024 * 1024 * 1024) {
       
        Swal.fire({
          title: 'Error! No Face Detected',
          text: 'File size exceeds 2GB.',
          icon: 'error',
          confirmButtonText: 'Try Again',
          confirmButtonColor: '#000',
          customClass: {
            confirmButton: 'btn-custom', // Custom class for the button
          }
      });

        setTimeout(function () {
          setShowStep2(false);
          setShowStep1(true);
      }, 1000);
      

      }
  
      const videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(file);
  
      videoElement.onloadedmetadata = async () => {
        const durationInSeconds = videoElement.duration;
        const maxDurationInSeconds = 5 * 60; // 5 minutes = 300 seconds
  
        // **Early Validation for Duration:**
        if (durationInSeconds > maxDurationInSeconds) {
         
          Swal.fire({
            title: 'Error! No Face Detected',
            text: 'Video exceeds 5 minutes. Please upload a video shorter than 5 minutes.',
            icon: 'error',
            confirmButtonText: 'Try Again',
            confirmButtonColor: '#000',
            customClass: {
              confirmButton: 'btn-custom', // Custom class for the button
            }
        });
          setTimeout(function () {
            setShowStep2(false);
            setShowStep1(true);
        }, 1000);
        
          
        }else{
      // Send the request using axios
      const response = await axios.post(
        `${Config.BASE_URL}/api/BackgroundService/extract-images`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      // Handle the response
     // console.log('Success:', response.data.responsedata.isSuccess);
      var res =response.data.responsedata;
      if(res.isSuccess)
      {
        const voicesData: extractimages[] =response.data.responsedata.images.map((data: any) => ({
          path: data.path,
          frameNumber: data.frameNumber
      }));

      setframeimages(voicesData);

        setuserFolderPath(res.userFolderPath);
        setbaseDirectory(res.baseDirectory);
        setuniqueName(res.uniqueFolderName);
      
        setisface(true); // Set to true when faces are detected
       // setLoading(false);
    }else{
      Swal.fire({
        title: 'Error! No Face Detected',
        text: response.data.responsedata.message,
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#000',
        customClass: {
          confirmButton: 'btn-custom', // Custom class for the button
        }
    });
    }
  }
  }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
  
  const handlePlayPause = (index: number, voiceUrl: string) => {
    if (audioRef.current) {
      if (currentlyPlayingIndex === index && isPlaying) {
        // If the currently playing audio is clicked, pause it
        audioRef.current.pause();
        setIsPlaying(false);
        setCurrentlyPlayingIndex(null);
      } else {
        // Play the selected audio
        audioRef.current.src = voiceUrl;
        audioRef.current.play();
        setIsPlaying(true);
        setCurrentlyPlayingIndex(index); // Set the new currently playing voice
      }
    }
  };

  
  const handleFileChange = useCallback((file: File) => {
    
  
    if (file) {
      // Revoke the previous object URL if it exists
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
      
      // Set the new file
      setUploadedFile(file);
      const objectUrl = URL.createObjectURL(file);
      setPreviewUrl(objectUrl);
      setShowStep1(false);
      setShowStep2(true);
      setKey(prevKey => prevKey + 1); // Increment the key to force re-render of video player
      
    }
  }, [previewUrl]); // Add previewUrl to the dependencies array

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setLoading(true);
   
    //naveed
    if (acceptedFiles && acceptedFiles.length > 0) {
      handleFileChange(acceptedFiles[0]);

      VideoToImg(acceptedFiles[0]);  
    }
    
  }, [handleFileChange]); // Add handleFileChange to the dependencies array

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: {
      'video/*': ['.mp4', '.avi', '.mov']
    },
    multiple: false,
  }); // Add onDrop to the dependencies array
  
  useEffect(() => {
    const fetchVoices = async () => {
      try {
        // Replace with your API URL
        const response = await axios.get(`${Config.BASE_URL}/api/VideoProcessing/Allvoices`);
        const voicesData: Voice[] = response.data.data.map((data: any) => ({
          id: data.voice_id,
          name: data.name,
          audioUrl: data.preview_url
      }));

      // Update state with fetched voices
      setVoices(voicesData);
      } catch (err) {
        
      } finally {
        setLoading(false);
      }
    };

    fetchVoices();
    return () => {
      // Cleanup function to revoke object URL when component unmounts
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  // Function to handle change content click in Step 2
  const handleChangeContent = () => {
   
    open();

  };

  
  const handleFaceClick = (image: any ,index: number  ) => {
           const targ =image.path;
          settargetfile(targ);
      setActiveFaceIndex(index);
  };
  // Function to handle image click in Step 2
  const handleImageClick = (imageUrl: string) => {
    debugger
    if (activeFaceIndex !== null) {
      setSelectedImages((prevImages) => ({
        ...prevImages,
        [activeFaceIndex]: imageUrl,
      }));
    }
  };

  // Function to handle choose image click in Step 2 // Created a new onImageDrop callback to handle image
  const onImageDrop = useCallback(
    

    async (acceptedFiles: File[]) => {
      if (acceptedFiles && acceptedFiles.length > 0 && activeFaceIndex !== null) {
      
        const file = acceptedFiles[0];
        const imageUrl = URL.createObjectURL(file); // Preview the image locally
  
        const formData = new FormData();
        formData.append("file", file);
  
        try {
          const response = await fetch(`${Config.BASE_URL}/api/Upload/uploadV2`, {
            method: "POST",
            body: formData,
          });
  
          if (!response.ok) {
            throw new Error("Failed to upload the image.");
          }
  
          const data = await response.json();
            Swal.fire({
            title: 'Success',
            text: 'Image uploaded successfully',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#000'
            });

          setSelectedImages((prevImages) => ({
            ...prevImages,
            [activeFaceIndex]: data.remotePath, // Use the remote image URL from the server
          }));
        } catch (error) {
          console.error("Error during image upload:", error);
          alert("Failed to upload the image. Please try again.");
        } finally {
          setImageDropzoneOpen(false); // Close the dropzone after handling
        }
      }
    },
    [activeFaceIndex]
  );
  

  const {
    getRootProps: getImageRootProps,
    getInputProps: getImageInputProps,
    open: openImageDropzone,
  } = useDropzone({
    onDrop: onImageDrop,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png']
    },
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  const handleChooseImage = () => {
    
    if (activeFaceIndex !== null) {
      setImageDropzoneOpen(true);
      openImageDropzone();
    }
  };

  // STEP 3: Select Voice
  
  // Array of voice objects
   

    const handleVoiceSelection = (index: number,data: any) => {
      setSelectedVoiceIndex(index);
  
      
      setSelectedVoice(data);
    };
    
    const handleSubmitEvent= async () => {
      try {
        console.log(uploadedFile,"Upload Video")
        console.log(selectedImages,"src")
        console.log(selectedVoiceIndex,"voice")
        // Define the body of the request
        
    // Append the data to the FormData object
    if (uploadedFile){
      const formData = new FormData();
      if (targetfile) {
        
        formData.append('SourceImage', selectedImages[0]);
        formData.append('TargetImage', targetfile);
      
        formData.append('VideoFile', uploadedFile);
        formData.append('VoiceId', selectedVoice);
        formData.append('uniqueFolderName', uniqueName);
        formData.append('userFolderPath', userFolderPath);
        formData.append('baseDirectory', baseDirectory);

        const email = sessionStorage.getItem('email');

// Append the email to formData
    if (email) {
     formData.append('Email', email);
      }

        setLoading(true); // Start spinner before making the request

        try {
          const response = await axios.post(
            `${Config.BASE_URL}/api/VideoProcessing/ProcessNewVideoV2`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          if(response.data.responsedata.isSuccess)
           {
            Swal.fire({
              title: 'Success',
              text: response.data.responsedata.message,
              icon: 'success',
               confirmButtonText: 'OK',
               confirmButtonColor: '#000',
               customClass: {
                 confirmButton: 'btn-custom', // Custom class for the button
               }
              }).then(() => {
                // Redirect to the desired page after SweweetAlert confirmation
                navigate('/upload'); // Redirect to localhost:3000/upload
            });
           }else{
            Swal.fire({
              title: 'Error!',
              text: response.data.responsedata.message,
              icon: 'error',
              confirmButtonText: 'Try Again',
              confirmButtonColor: '#000',
              customClass: {
                confirmButton: 'btn-custom', // Custom class for the button
              }
          });
           }
       
           navigate('/upload'); // Redirect to localhost:3000/upload
        } catch (error) {
          console.error('Error:', error);
          Swal.fire({
            title: 'Error!',
            text: 'Something went wrong with the API call.',
            icon: 'error',
            confirmButtonText: 'Try Again',
            confirmButtonColor: '#000',
            customClass: {
              confirmButton: 'btn-custom', // Custom class for the button
            }
        });
        } finally {
          setLoading(false); // Stop spinner after the request is finished
        }
     
      } else {
        // Handle
        Swal.fire({
          title: 'Error!',
          text: 'Target image is not selected.',
          icon: 'error',
          confirmButtonText: 'Try Again',
          confirmButtonColor: '#000',
        customClass: {
          confirmButton: 'btn-custom', // Custom class for the button
        }
      });
        console.error('Target image is not selected.');
        // You might also want to display an error message to the user
      }
     

      
     
   
    }
        
        
    
    // Assuming you have the video file path or file input
    
    
    
        // Send a POST request to the server
    
        // Handle the response (success)
       // console.log('successfully generated:', response.data.video_path);
        
      
        
      } catch (error) {
        // Handle errors
        console.error('Error :', error);
        
       
      }
    };
    // Function to handle audio upload
    const handleAudioUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const validAudioTypes = ['audio/mpeg', 'audio/wav', 'audio/ogg'];
        if (validAudioTypes.includes(file.type)) {
          setUploadedAudio(file);
        } else {
          alert('Please upload a valid audio file (MP3, WAV, or OGG)');
        }
      }
    };


  /**
   * Retrieves the current theme from the context.
   * 
   * @returns The current theme object.
   */
  const theme = useTheme();

  return (
    <PageContainer>
       {loading ? (
       <ProgressiveLoader /> 

    ) : (

      <>
      {/* STEP 1 Begin */}

      <div
        style={{
          opacity: showStep1 ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
          position: 'absolute',
          width: '100%',
          zIndex: showStep1 ? 1 : -1
        }}
      >
        <h2 style={{ fontWeight: 'normal', color: theme.colors.lightSecondary }}>Step 1:</h2>
        <h1 style={{ color: theme.colors.background }}>Upload Video</h1>
        <FlexContainer>
          <DropzoneContainer {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the video file here ...</p>
            ) : (
              <p>Drag and drop a video file here, or click to select a file</p>
            )}
          </DropzoneContainer>
        </FlexContainer>
      </div>

      {/* STEP 2 Begin */}
      <div
        style={{
          opacity: showStep2 ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
          position: 'absolute',
          width: '100%',
          zIndex: showStep2 ? 1 : -1
        }}
      >
        <Container>
        <LeftBlock style={{ opacity: showStep2 ? 1 : 0 }}>
            {uploadedFile && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <FileInfo>
                    <FileIcon />
                    <FileName>{uploadedFile.name}</FileName>
                  </FileInfo>
                  <ChangeButton onClick={handleChangeContent}>Change Content</ChangeButton>
                </div>
                <VideoPreviewContainer>
                {previewUrl && (
                    <VideoPlayer key={key} controls>
                      <source src={previewUrl} type={uploadedFile.type} />
                      Your browser does not support the video tag.
                    </VideoPlayer>
                  )}
                </VideoPreviewContainer>
                <UploadedFileInfo>
                  <p>Size: {(uploadedFile.size / (1024 * 1024)).toFixed(2)} MB</p>
                  <p>Type: {uploadedFile.type}</p>
                </UploadedFileInfo>
              </>
            )}
          </LeftBlock>
          <RightBlock style={{ opacity: showStep2 ? 1 : 0 }}>
            <h2 style={{ fontWeight: 'normal', color: theme.colors.lightSecondary, margin: '0' }}>Step 2:</h2>
            <SubHeadingContainer>
              <SubHeadingBlock>
                <SubHeading>Detected Faces</SubHeading>
                {isface ? (
  <DetectedContainer>
    {frameimages.map((extractimages, index) => (
      <DetectedFace
        key={index}
        isActive={activeFaceIndex === index}
        onClick={() => handleFaceClick(extractimages, index)}
      >
        <Square>
          <img
            src={extractimages.path}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: 'inherit',
            }}
          />
        </Square>
        <Arrow>→</Arrow>
        <CircleDetectedFace>
          {selectedImages[index] ? (
            <img
              src={selectedImages[index]}
              alt={`Selected Face ${index}`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: 'inherit',
              }}
            />
          ) : (
            <Circle />
          )}
        </CircleDetectedFace>
      </DetectedFace>
    ))}
  </DetectedContainer>
) : (

  <ProgressiveLoader /> 

  )}

              </SubHeadingBlock>
              <SubHeadingBlock>
                <SubHeading>Choose Face</SubHeading>
                <ChooseImageButton onClick={handleChooseImage}>+ Choose Image</ChooseImageButton>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <Circle onClick={() => handleImageClick('https://th.bing.com/th/id/OIP.nUnYx1GjVEdf7km72Hf-zwHaHa?rs=1&pid=ImgDetMain')}>
                    <img
                      src={'https://th.bing.com/th/id/OIP.nUnYx1GjVEdf7km72Hf-zwHaHa?rs=1&pid=ImgDetMain'}
                      alt='Faces'
                    />
                  </Circle>

                  <Circle onClick={() => handleImageClick('https://content.xaloon.co/BGhost/swap.jpeg')}>
                    <img src={'https://content.xaloon.co/BGhost/swap.jpeg'} alt='Faces'  />
                  </Circle>
                  <Circle onClick={() => handleImageClick('https://th.bing.com/th/id/OIP.v6hRLtXI5NEuSstIGEeCSAHaE8?rs=1&pid=ImgDetMain')}>
                    <img src={'https://th.bing.com/th/id/OIP.v6hRLtXI5NEuSstIGEeCSAHaE8?rs=1&pid=ImgDetMain'} alt='Faces'  />
                  </Circle>
                  <Circle onClick={() => handleImageClick('https://th.bing.com/th/id/OIP.wSMDzwhm-7PBFckBTZuj6QAAAA?rs=1&pid=ImgDetMain')}>
                    <img src={'https://th.bing.com/th/id/OIP.wSMDzwhm-7PBFckBTZuj6QAAAA?rs=1&pid=ImgDetMain'} alt='Faces'  />
                  </Circle>
                  <Circle onClick={() => handleImageClick('https://th.bing.com/th/id/OIP.nUnYx1GjVEdf7km72Hf-zwHaHa?rs=1&pid=ImgDetMain')}>
                    <img src={'https://th.bing.com/th/id/OIP.nUnYx1GjVEdf7km72Hf-zwHaHa?rs=1&pid=ImgDetMain'} alt='Faces'  />
                  </Circle>
                  <Circle onClick={() => handleImageClick('https://th.bing.com/th/id/OIP.nUnYx1GjVEdf7km72Hf-zwHaHa?rs=1&pid=ImgDetMain')}>
                    <img src={'https://th.bing.com/th/id/OIP.nUnYx1GjVEdf7km72Hf-zwHaHa?rs=1&pid=ImgDetMain'} alt='Faces'  />
                  </Circle>
                </div>
              </SubHeadingBlock>
            </SubHeadingContainer>
            <NextButton onClick={() => { setShowStep2(false); setShowStep3(true); }}>Next</NextButton>
          </RightBlock>
        </Container>
      </div>

      {/* STEP 3 Begin */}
      <div
        style={{
          opacity: showStep3 ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
          position: 'absolute',
          width: '100%',
          zIndex: showStep3 ? 1 : -1
        }}
      >
        <Container>
          <LeftBlock style={{ opacity: showStep3 ? 1 : 0 }}>
            {uploadedFile && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <FileInfo>
                    <FileIcon />
                    <FileName>{uploadedFile.name}</FileName>
                  </FileInfo>
                  <ChangeButton onClick={() => { setShowStep3(false); setShowStep2(true); }}>Back</ChangeButton>
                </div>
                <VideoPreviewContainer>
                  {previewUrl && (
                     <VideoPlayer key={key} controls>
                     <source src={previewUrl} type={uploadedFile.type} />
                     Your browser does not support the video tag.
                   </VideoPlayer>
                  )}
                </VideoPreviewContainer>
                <UploadedFileInfo>
                  <p>Size: {(uploadedFile.size / (1024 * 1024)).toFixed(2)} MB</p>
                  <p>Type: {uploadedFile.type}</p>
                </UploadedFileInfo>
              </>
            )}
          </LeftBlock>
          <RightBlock style={{ opacity: showStep3 ? 1 : 0 }}>
            <h2 style={{ fontWeight: 'normal', color: theme.colors.lightSecondary, margin: '0' }}>Step 3:</h2>
            <SubHeadingContainer>
              <SubHeadingBlockVoice>
                <SubHeading> <RiVoiceprintFill style={{'color': theme.colors.textDark}} /> Select Your Voice</SubHeading>
                <UploadAudioButton as="label" theme={theme}>
                  + Upload Audio
                  <input
                    id="audio-upload"
                    type="file"
                    accept="audio/*"
                    style={{ display: 'none' }}
                    onChange={handleAudioUpload}
                  />
                </UploadAudioButton>
                {uploadedAudio && <p>Uploaded: {uploadedAudio.name}</p>}
                <SubHeading style={{marginTop: 15}}>Choose a default voice you want to use</SubHeading>
                <VoiceContainer>
                  {voices.map((voice ,index) => (
                     <VoiceRow key={voice.name}>
                       <PlayIcon onClick={() => handlePlayPause(index, voice.audioUrl)}>
              {currentlyPlayingIndex === index && isPlaying ? <FaPauseCircle /> : <FaPlayCircle />}
            </PlayIcon>
                      <VoiceName>{voice.name}</VoiceName>
                      <TagWrapper>
                        <VoiceTag>{voice.name}</VoiceTag>
                        <VoiceTag>+4 more</VoiceTag>
                      </TagWrapper>
                      <SelectVoiceButton  onClick={() => handleVoiceSelection(index,voice.id)}
                        style={{
                          background: selectedVoiceIndex === index ? 'black' : '',
                          color: selectedVoiceIndex === index ? 'white' : theme.colors.textDark
                        }}>
                          <RiVoiceprintFill /> {selectedVoiceIndex === index ? 'Selected Voice' : 'Select Voice'}
                      </SelectVoiceButton>
                    </VoiceRow>
                  ))}
                </VoiceContainer>
                <audio ref={audioRef} onEnded={() => setIsPlaying(false)} />
      
              </SubHeadingBlockVoice>
            </SubHeadingContainer>
            <NextButton onClick={() => handleSubmitEvent()}>Next</NextButton>
          </RightBlock>
        </Container>
      </div>

      {/* // Add imageDropzoneOpen to the dependencies array */}
      {imageDropzoneOpen && (
        <div {...getImageRootProps()} style={{'display':'none'}}>
          <input {...getImageInputProps()} />
        </div>
      )}  

</>
    )}
    </PageContainer>
  );
};

export default VideoUploadPage;
