import React from 'react';
import styled from '@emotion/styled';
import { FaDownload } from 'react-icons/fa';
import  ButtonTheme  from '../components/common/Button';
import { useLocation } from 'react-router';
import axios from 'axios';
import Config from '../BaseUrl';


const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
`;

const Heading = styled.h1`
  font-size: 3rem;
  color: ${props => props.theme.colors.lightSecondary}; /* Tomato color */
  margin-bottom: 2rem;
  margin-top: 0rem;
  font-family: inherit; /* Custom font if available */
`;

const VideoPlayer = styled.video`
  width: 100%;
  max-width: 800px;
  height: auto;
  margin: 10px auto;
  display: block;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`;



const Result: React.FC = () => {
  const location = useLocation(); // Use to get state passed from previous page
  const videoUrl = location.state?.videoUrl; // Access the video URL from state
  const localPath = location.state?.localPath; // Access the video URL from state

  const handleDownload = async () => {
    const folderName = localPath; // Replace with the actual folder name
 
    try {
      const response = await axios.get(`${Config.BASE_URL}/api/VideoProcessing/download-video?folderName=${folderName}`, {
        responseType: 'blob', // Important for file downloading
      });

      // Create a link element and trigger a download
     
// Assuming response.data contains your JSON response
  const videoPath = response.data.video_path;

  const link = document.createElement('a');
  link.href = videoPath; // Use the video URL directly
  link.setAttribute('download', 'output1.mp4'); // Set the file name

// Append to the body (optional)
  document.body.appendChild(link);

// Programmatically click the link to trigger the download
      link.click();

// Clean up: remove the link
    document.body.removeChild(link);


    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <PageContainer>
      <Heading>Magic is Here!</Heading>
      <VideoPlayer controls>
        <source src={videoUrl}  type="video/mp4" />
        Your browser does not support the video tag.
      </VideoPlayer>
          <ButtonTheme
              padding="1rem 2rem"
              background="Transparent"
              border="2px dashed #838383"
              borderRad="8px"
              onClick={handleDownload}
          > <FaDownload />  &nbsp;
              Download
          </ButtonTheme>
      
    </PageContainer>
  );
};

export default Result;
