// src/components/SignInForm.tsx
import React, { useState } from 'react';
import styled from '@emotion/styled';
import ButtonTheme from './Button';
import qs from 'qs'; // Make sure to install 'qs' package
import Swal from 'sweetalert2';
import axios from 'axios'
import Config from '../../BaseUrl';

interface StyledProps {
  $margin?: string;
  $textAlign?: string;
}

const Heading = styled.h2`
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px; /* 109.375% */
  background: radial-gradient(81.15% 39.38% at 51.91% 38.33%, #FFF 0.34%, #B0B0B0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  text-align: left; /* Optional: Center the text */
  line-height: 1;
`;


const HeadingForgot = styled.h3`
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px; /* 109.375% */
  background: radial-gradient(81.15% 39.38% at 51.91% 38.33%, #FFF 0.34%, #B0B0B0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  text-align: left; /* Optional: Center the text */
  line-height: 1;
`;

const Para = styled.p`
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: left;
  margin: 8px 0 15px 0;
`;

// Label
const Label = styled.label`
  color: ${props => props.theme.colors.backgroundLight}; 
  font-family: inherit; /* Fallback fonts */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin-bottom: 8px;
`;

// Input
const Input = styled.input`
  border-radius: 5px;
  background: ${props => props.theme.colors.bgLightWhite}; ;
  border: 1px solid #B0B0B0; /* Add border color to match the label */
  font-family: inherit; /* Fallback fonts */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 10px;
  width: 100%; /* Full width for responsive design */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for border and shadow */
  
  &:focus {
    outline: none;
    border-color: #1E90FF; /* Change border color on focus */
    box-shadow: 0 0 8px rgba(30, 144, 255, 0.5); /* Add a glow effect */
  }
`;

// Form Field
const FormField = styled.div<StyledProps>`
    margin: ${props => props.$margin || '0 0 0 0'};
`;

// Styled toggle text
const ToggleText = styled.div<StyledProps>`
  cursor: pointer;
  color: #fff;
  margin: ${props => props.$margin || '10px 0 0 0'};
  text-align: ${props => props.$textAlign || 'center'};
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }

  span {
    font-weight: bold;
  }
`;

const SignInForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [Code, setCode] = useState("");
  const [newpassword, setnewpassword] = useState("");

  const [isSignUp, setIsSignUp] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isForgotPasswordCode, setisForgotPasswordCode] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [formDatav2, setFormDatav2] = useState({ email: "", password: "" });
  const handleToggle = () => {
    setIsSignUp(!isSignUp);
    setIsForgotPassword(false); // Reset forgot password state when toggling
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
  };

  const handleBackToSignIn = () => {
    setIsForgotPassword(false);
  };
  const [formData, setFormData] = useState({
    First_Name: '',
    Last_Name: '',
    Email: '',
    user_Name:'',
    Password: ''
    //Password
  });
  
  const handleInputLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormDatav2(prev => ({ ...prev, [name]: value }));
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>)  => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
 
  const handleInputChangeforgot = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value); // Set the input value correctly
  };
  const handleInputChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {

    setCode(e.target.value); // Set the input value correctly
  };
  const handleInputNewPassword= (e: React.ChangeEvent<HTMLInputElement>) => {

    setnewpassword(e.target.value); // Set the input value correctly
  };
  const updateUserProfile = async () => {
    if (!email) {
      Swal.fire({
        title: "Input Required",
        text: "Please enter your email address.",
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: '#000',
      });
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Swal.fire({
        title: "Invalid Email",
        text: "Please enter a valid email address.",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#000",
      });
      return;
    }
    try {
      
      const response = await axios.post(
        `${Config.BASE_URL}/api/user/forgetPasswordRequestV2`,
        {
          Params: [
            { key: "P_Email", value: email }, 
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
     
     console.log(response.data);
      if (response.data.code === 200) {
        Swal.fire({
          title: "Reset Link Sent",
          text: "A reset link has been sent to your email address.",
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#000",
        });
        setIsForgotPassword(false);
         setisForgotPasswordCode(true);
         setCode("");
      } else {
        Swal.fire({
          title: "Error",
          text: response.data.msg || "Failed to send the reset link. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#000",
        });
      }
   
     
    } catch (error) {
      Swal.fire({
        title: "Forgot Password",
        text:  "Failed to send the reset link. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: '#000',
      });
      
    }
  };
  

  const updateCode = async () => {
    if (!Code) {
      Swal.fire({
        title: "Input Required",
        text: "Please enter your Code",
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: '#000',
      });
      return;
    }
   // Validate password input
   if (!newpassword.trim()) {
    Swal.fire({
      title: "Input Required",
      text: "Please enter your new password.",
      icon: "warning",
      confirmButtonText: "OK",
      confirmButtonColor: "#000",
    });
    return;
  }
   
    try {

      const response = await axios.post(
        `${Config.BASE_URL}/api/user/resetPassword`,
        {
          Params: [
            { key: "P_Id", value: Code },
            { key: "P_Password", value: newpassword },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  debugger
      if(response.data.code === 200) {  
      Swal.fire({
        title: "Password Reset Successful",
        text: "Your password has been successfully reset. Please log in with your new password.",
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: '#000',
        customClass: {
          confirmButton: 'btn-custom', // Custom class for the button
        }
      });
      setIsForgotPassword(false);
      setisForgotPasswordCode(false);
      setIsSignUp(false);
    }else{
      Swal.fire({
        title: "Error",
        text: response.data.message || "Failed to reset password. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#000",
      });
    }
    } catch (error) {
      Swal.fire({
        title: "Forgot Password",
        text:  "Failed to send the reset link. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: '#000',
      });
      
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
      user_Name: `${prevData.First_Name} ${prevData.Last_Name}`
    }));

     };
    
     
     const handleLogin = async (event: React.FormEvent<HTMLButtonElement>) => {
      event.preventDefault();
  
      const { email, password } = formDatav2;
  
      // Validation
      if (!email.trim()) {
        setErrorMessage("Email is required.");
        return;
      }
  
      if (!password.trim()) {
        setErrorMessage("Password is required.");
        return;
      }
  
      setErrorMessage(""); // Clear errors if validation passes
  
      try {
        const response = await axios.post(
          `${Config.BASE_URL}/User/api/auth/login`,
          qs.stringify(formDatav2),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
  
        const responseCode = response.data.code;
        debugger;
        if (responseCode === 200) {
          const userData = response.data.data[0];
  
          // Save user data to sessionStorage
          sessionStorage.setItem("username", userData.User_Name);
          sessionStorage.setItem("email", userData.Email);
          sessionStorage.setItem("company_name", userData.company_name);
          sessionStorage.setItem("First_Name", userData.First_Name);
          sessionStorage.setItem("Last_Name", userData.Last_Name);
          sessionStorage.setItem("phoneNumber", userData.phoneNumber);
          sessionStorage.setItem("token", userData.token);
          sessionStorage.setItem("P_User_Id", userData.Id);
          sessionStorage.setItem("Unique", userData.Id);
          sessionStorage.setItem("footerset", "true");
          sessionStorage.setItem("password",password);
  
          Swal.fire({
            title: "Success",
            text: "Login successful",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#000",
          }).then(() => {
       
            window.location.href = "/dashboard"; // Redirect to the home page
          });
        } else {
          Swal.fire({
            title: "Error",
            text: response.data.message || "Login failed. Please check your credentials.",
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#000",
          });
        }
      } catch (error) {
        console.error("Error during login:", error);
        Swal.fire({
          title: "Error!",
          text: "Network error. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#000",
        });
      }
    };
  
    const handleSignUpSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
    
      // Validate required fields
      const { First_Name, Last_Name, Email, Password } = formData;
      if (!First_Name.trim() || !Last_Name.trim() || !Email.trim() || !Password.trim()) {
        Swal.fire({
          title: "Validation Error",
          text: "All fields are required. Please fill them in.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#000",
        });
        return;
      }
    
      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(Email)) {
        Swal.fire({
          title: "Invalid Email",
          text: "Please enter a valid email address.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#000",
        });
        return;
      }
    
      // Validate password strength
      if (Password.length < 4) {
        Swal.fire({
          title: "Weak Password",
          text: "Password must be at least 4 characters long.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#000",
        });
        return;
      }
    
      try {
        const response = await axios.post(
          `${Config.BASE_URL}/User/api/auth/signup`,
          qs.stringify(formData),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
    debugger
        if (response.data.code === 200) {
          const userData = response.data.data[0];
    
          // Save user details in sessionStorage
          sessionStorage.setItem("username", userData.User_Name);
          sessionStorage.setItem("email", userData.Email);
          sessionStorage.setItem("company_name", userData.company_name);
          sessionStorage.setItem("First_Name", userData.First_Name);
          sessionStorage.setItem("Last_Name", userData.Last_Name);
          sessionStorage.setItem("phoneNumber", userData.phoneNumber);
          sessionStorage.setItem("token", userData.token);
          sessionStorage.setItem("P_User_Id", userData.Id);
    
          Swal.fire({
            title: "Success",
            text: "Sign up successful",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#000",
          }).then(() => {
            setIsSignUp(false);
          });



        } else {
          Swal.fire({
            title: "Error",
            text: response.data.msg,
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#000",
          });
        }
      } catch (error: any) {
        Swal.fire({
          title: "Error",
          text: error.response?.data?.message || "Failed to create account.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#000",
        });
      }
    };
  if (isForgotPassword) {
    return (
      <form>
        <HeadingForgot>Reset Password</HeadingForgot>
        <Para>Enter your email address to reset your password.</Para>
        <FormField $margin='0 0 20px 0'>
          <Label htmlFor="email">Email:</Label>
          <Input 
            type="email" 
            id="email" 
            name="email" 
           
           // value={email} 
            onChange={handleInputChangeforgot} 
            required // HTML5 validation
          />
        </FormField>

        <ButtonTheme 
          primary 
          type="button" 
          width="100%" 
          background='Transparent' 
          border='1px solid'
          onClick={updateUserProfile} // Attach the function to onClick
        >
          Send Reset Link
        </ButtonTheme>
        
        <ToggleText 
          onClick={handleBackToSignIn} 
          $textAlign='center' 
          $margin='10px 0'
        >
          Back to <span>Sign In</span>
        </ToggleText>
      </form>
    );
  }
  if (isForgotPasswordCode) {
    return (
      <form>
      <HeadingForgot>New Password</HeadingForgot>
        <Para>Please Enter Code </Para>
        <FormField $margin='0 0 20px 0'>
          <Label htmlFor="Code">Code:</Label>
          <Input 
            type="text" 
            id="Code" 
            name="Code" 
            value={Code} 
            onChange={handleInputChangeCode} 
            required
          />
        </FormField>
        <FormField $margin='0 0 20px 0'>
          <Label htmlFor="NewPassword">New Password:</Label>
          <Input 
            type="text" 
            id="NewPassword" 
            name="NewPassword" 
            onChange={handleInputNewPassword}
            required // Adds native HTML5 validation 
          />
        </FormField>
        <ButtonTheme 
          primary 
          type="button" 
          width="100%" 
          background='Transparent' 
          border='1px solid'
          onClick={updateCode} // Attach the function to onClick
           // Adds native HTML5 validation
        >
          Password Reset
        </ButtonTheme>
        
      
      </form>
    );
  }
  return (
    <div>
      {isSignUp ? (
      <form onSubmit={handleSignUpSubmit}>
      <Heading>Hi there!</Heading>
      <Para>Create your account below</Para>
      <FormField>
        <Label htmlFor="firstName">First Name:</Label>
        <Input
          type="text"
          onChange={handleChange}
          id="firstName"
          name="First_Name"
          value={formData.First_Name}
          required
        />
      </FormField>
      <FormField>
        <Label htmlFor="lastName">Last Name:</Label>
        <Input
          type="text"
          onChange={handleChange}
          id="lastName"
          name="Last_Name"
          value={formData.Last_Name}
          required
        />
      </FormField>
      <FormField>
        <Label htmlFor="email">Email:</Label>
        <Input
          type="email"
          onChange={handleChange}
          id="email"
          name="Email"
          value={formData.Email}
          required
        />
      </FormField>
      <FormField>
        <Label htmlFor="password">Password:</Label>
        <Input
          type="password"
          onChange={handleChange}
          id="password"
          name="Password"
          value={formData.Password}
          required
        />
      </FormField>
      <br />
        <ButtonTheme primary type="submit" width="100%" >Sign Up</ButtonTheme>
        <ToggleText onClick={handleToggle}>
          Already a member? <span>Sign in</span>
        </ToggleText>
      </form>
      ) : (
        <form>
          <Heading>Hi there!</Heading>
          <Para>Welcome to B-Ghost, so happy to see you!</Para>
          <FormField $margin="0 0 20px 0">
        <Label htmlFor="email">Email:</Label>
        <Input
          type="email"
          id="email"
          name="email"
          value={formDatav2.email}
          onChange={handleInputLoginChange}
          required
        />
      </FormField>
          <FormField>
        <Label htmlFor="password">Password:</Label>
        <Input
          type="password"
          id="password"
          name="password"
          value={formDatav2.password}
          onChange={handleInputLoginChange}
          required
        />
      </FormField>
      {errorMessage && <Para style={{ color: "white" }}>{errorMessage}</Para>}
          <ToggleText 
            onClick={handleForgotPassword} 
            $textAlign='left' 
            $margin='1px 0 20px 0'
          >
            Forgot Password?
          </ToggleText>

          <ButtonTheme primary  type="submit" width="100%" background='Transparent' border='1px solid' 
          onClick={(event) => handleLogin(event)} 
           >Sign In</ButtonTheme>
          <ToggleText  onClick={handleToggle} >
            Not a member? <span>Sign Up</span>
          </ToggleText>
        </form>
      )}
    </div>
  );
};

export default SignInForm;
