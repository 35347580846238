/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import BlogCard from "../../components/BlogCard";
import { blogs } from "../../data/blogs";
import { Link } from "react-router-dom";

const blogPageStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const blogListStyle = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
`;

const BlogPage: React.FC = () => {
  return (
    <div css={blogPageStyle}>
      <h1>All Blogs</h1>
      <div css={blogListStyle}>
        {blogs.map((blog) => (
          <Link to={`/blog/${blog.title}`} key={blog.id} style={{ textDecoration: "none" }}>
            <BlogCard blog={blog} />
          </Link>
        ))}
      </div>
    </div>

    
  );
};

export default BlogPage;
