/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import Img from '../Img';


// Emotion CSS styles
const footerContainer = css`
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #111;
  border-top: 1px solid #ddd;
`;

const footerContent = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const footerLogoSection = css`
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const socialIcons = css`
  margin-top: 10px;
  display: flex;
  gap: 10px;
  font-size: 0.7rem;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const socialIcon = css`
  width: 30px;
  height: 30px;
  filter: grayscale(100%);
  transition: filter 0.3s ease;

  &:hover {
    filter: grayscale(0%);
  }
`;

const footerLinks = css`
  text-align: left;

  h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    margin-top: 0px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 8px;
  }

  a {
    color: #111;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const footerContact = css`
  text-align: left;

  h4 {
    font-size: 1.2rem;
    margin-top: 0px;
  }

  a {
    color: #111;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const copyrightText = css`
  font-size: 0.9rem;
  color: #777;
`;

// Footer Component
const Footer: React.FC = () => {
  return (
    <footer css={footerContainer}>
      <div css={footerContent}>
        {/* Logo and Social Icons */}
        <div css={footerLogoSection}>
          <Img ImgName="logos/logo_black.png" alt="Logo" width={250} />
          <div css={socialIcons}>
            <a href="#" aria-label="LinkedIn">
              <img
                src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                alt="LinkedIn"
                css={socialIcon} // Correct usage
              />
            </a>
            <a href="#" aria-label="X (formerly Twitter)">
              <img
                src="https://cdn-icons-png.flaticon.com/512/733/733579.png"
                alt="X"
                css={socialIcon} // Correct usage
              />
            </a>
            <a href="#" aria-label="Facebook">
              <img
                src="https://cdn-icons-png.flaticon.com/512/733/733547.png"
                alt="Facebook"
                css={socialIcon} // Correct usage
              />
            </a>
            <a href="#" aria-label="Instagram">
              <img
                src="https://cdn-icons-png.flaticon.com/512/733/733558.png"
                alt="Instagram"
                css={socialIcon} // Correct usage
              />
            </a>
          </div>
        </div>

        {/* Company Links */}
        <div css={footerLinks}>
          <h4>Company</h4>
          <ul>
            <li>
              <a href="#">About Us</a>
            </li>
            <li>
              <a href="/blog">Blog</a>
            </li>
            <li>
              <a href="#">Research</a>
            </li>
            <li>
              <a href="#">News</a>
            </li>
          </ul>
        </div>

        {/* Contact Information */}
        <div css={footerContact}>
          <h4>Contact Us</h4>
          <p>
            <a href="mailto:info@bghost.com">info@bghost.com</a>
          </p>
        </div>
      </div>

      <p css={copyrightText}>Copyright © B-Ghost. All rights reserved</p>
    </footer>
  );
};

export default Footer;
