import { useState } from "react";
import styled from "@emotion/styled";
import { FaPencilAlt } from "react-icons/fa";
import ButtonTheme from "../common/Button";
import InputFeild from "../common/InputFeild";
import Img from "../Img";
import Config from '../../BaseUrl';
import axios from 'axios';
const Container = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  width: 100%;
`;

const FeildContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 50%;
`;

const ImageContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin-top: 0;
  }
  .image {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-color: #e2e2e2;
    position: relative;

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .edit-button {
    position: absolute;
    bottom: 2px;
    left: 5px;
    font-size: 12px;
    cursor: pointer;
    background: #fff;
    border: 1px solid #ccc;
    padding: 0.15rem 0.5rem;
    svg {
    font-size: 10px;
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const ProfileForm: React.FC = () => {
  const [email, setEmail] = useState(sessionStorage.getItem("email") || "");
  const [UniqueId, setUniqueId] = useState(sessionStorage.getItem("Unique") || "");
  const [firstName, setFirstName] = useState(sessionStorage.getItem("First_Name") || "");
  const [lastName, setLastName] = useState(sessionStorage.getItem("Last_Name") || "");
  const [isDisabled, setIsDisabled] = useState(true);
  const [profile, setProfile] = useState('');

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setProfile(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      // Ensure firstName, lastName, and UniqueId are valid before making the request
      if (!firstName || !lastName || !UniqueId) {
        alert("Please fill in all required fields.");
        return;
      }
  
      // Make the API request
      const response = await axios.post(
        `${Config.BASE_URL}/User/api/auth/updateProfile`,
        {
          firstName,
          lastName,
          UniqueId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
     debugger
      // Check if the status code is 200 and handle accordingly
      if (response.status === 200) {

        sessionStorage.setItem('First_Name', firstName);
        sessionStorage.setItem('Last_Name', lastName);
        console.log("Profile updated successfully:", response.data);
        alert("Profile updated successfully!");
      } else {
        console.error("Failed to update profile. Response:", response);
        alert("Failed to update profile");
      }
    } catch (error) {
      // Improved error logging for debugging
      console.error("Error updating profile:");
      alert("An error occurred while updating the profile.");
    }
  };
  
  return (
    <form>
      <Container>
        <FeildContainer>
          <InputFeild label="Email Address" type="email"   value={email} setValue={setEmail} />
          <InputFeild label="First Name" type="text" value={firstName} setValue={setFirstName} />
          <InputFeild label="Last Name" type="text" value={lastName} setValue={setLastName} />
        </FeildContainer>

        <ImageContainer>
          <p>Profile Picture</p>
          <label htmlFor="image">
            <div className="image">
              {profile && <Img ImgName={profile} alt="Profile" className="img" />}
              <ButtonTheme
                type="button"
                onClick={() => document.getElementById('image')?.click()}
                className="edit-button"
              >
                <FaPencilAlt /> Edit
              </ButtonTheme>
            </div>
            <input type="file" id="image" onChange={handleImageChange} />
          </label>
        </ImageContainer>
      </Container>

      <ButtonTheme
        type="button"
        padding="0.5rem 3rem"
        background="#0C0C0C"
        borderRad="5px"
        primary
        onClick={handleUpdateProfile}
      >
        Save
      </ButtonTheme>
    </form>
  );
};

export default ProfileForm;
