// ProtectedUploadRoute.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../components/common/Modal';
import SignInForm from '../components/common/Login';
import VideoUploadPage from '../pages/UploadVideo';

const ProtectedUploadRoute: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const isAuthenticated = () => {
    // Add your authentication logic here
    const token = sessionStorage.getItem("token");
    return !!token;
   

  };

  useEffect(() => {
    if (!isAuthenticated()) {
      setIsModalOpen(true); // Open modal if not authenticated
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate('/'); // Redirect to login or any other route if needed
  };

  if (!isAuthenticated()) {
    return (
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <SignInForm />
      </Modal>
    );
  }

  return <VideoUploadPage />; // Render the actual component if authenticated
};

export default ProtectedUploadRoute;
