/** @jsxImportSource @emotion/react */
import React from "react";
import { useParams } from "react-router-dom";
import { css } from "@emotion/react";
import { blogs } from "../../data/blogs";

const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
`;

const titleStyle = css`
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
`;

const authorDateStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  color: #666;
  font-size: 14px;
  margin-bottom: 20px;
`;

const imageStyle = css`
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin: 20px 0;
  object-fit: cover;
`;

const longTextStyle = css`
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  text-align: justify;
  margin-top: 20px;
`;

const BlogDetails: React.FC = () => {
  const { title } = useParams<{ title: string }>();

  // Convert hyphens back to spaces for lookup
  const formattedTitle = title ? title.replace(/-/g, " ") : "";
  const blog = blogs.find((b) => b.title.toLowerCase() === formattedTitle.toLowerCase());

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <div css={containerStyle}>
      <h2 css={titleStyle}>{blog.title}</h2>
      <div css={authorDateStyle}>
        <p>By {blog.author}</p>
        <span>|</span>
        <span>{blog.date}</span>
      </div>
      <img src={blog.img} alt={blog.title} css={imageStyle} />
      <p css={longTextStyle}>{blog.longDescription}</p>
    </div>
  );
};

export default BlogDetails;