import React from 'react';
import styled from '@emotion/styled';

const CommunityContainer = styled.div`
  padding: 2rem;
`;

const Community: React.FC = () => {
  return (
    <CommunityContainer>
      <h1> Community</h1>
      <p>Coming Soon.</p>
    </CommunityContainer>
  );
};

export default Community;