import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../common/Header';
import styled from '@emotion/styled';
import Footer from '../common/Footer';
const MainContainer = styled.div`
  max-width: 1200px;
  margin: 90px auto 10px;
  padding: 0 20px;
`;

const MainLayout: React.FC = () => {
  const location = useLocation(); // Get the current route

  return (
    <>
       <Header />
      <MainContainer>
        <Outlet />
      </MainContainer>
      
      {/* Render Footer only if the current path is not "/upload" */}
      {location.pathname.toLowerCase() !== '/upload' && <Footer />}
      
    </>
  );
};

export default MainLayout;