import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Swal from "sweetalert2";
import ButtonTheme from "../common/Button";
import InputFeild from "../common/InputFeild";

const FeildContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
  width: 50%;
`;

const PasswordForm: React.FC = () => {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [savedPassword, setSavedPassword] = useState('');

  // Load the current password from sessionStorage on component mount
  useEffect(() => {
    const storedPassword = sessionStorage.getItem("password");
    if (storedPassword) {
      setSavedPassword(storedPassword);
    }
  }, []);

  const handleUpdateProfile = (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    if (!password || !newPassword || !confirmPassword) {
      Swal.fire("Error", "All fields are required!", "error");
      return;
    }

    if (password !== savedPassword) {
      Swal.fire("Error", "Current password is incorrect!", "error");
      return;
    }

    if (newPassword !== confirmPassword) {
      Swal.fire("Error", "New passwords do not match!", "error");
      return;
    }

    // Save new password to sessionStorage
    sessionStorage.setItem("password", newPassword);

    Swal.fire("Success", "Password updated successfully!", "success");
    setPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  return (
    <form onSubmit={handleUpdateProfile}>
      <FeildContainer>
        <InputFeild
          label="Current Password"
          type="password"
          value={password}
          setValue={setPassword}
        />
        <InputFeild
          label="New Password"
          type="password"
          value={newPassword}
          setValue={setNewPassword}
        />
        <InputFeild
          label="Confirm New Password"
          type="password"
          value={confirmPassword}
          setValue={setConfirmPassword}
        />
      </FeildContainer>

      <ButtonTheme
        type="submit"
        padding="0.5rem 3rem"
        background="#0C0C0C"
        borderRad="5px"
        primary
      >
        Save
      </ButtonTheme>
    </form>
  );
};

export default PasswordForm;
