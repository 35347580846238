// src/components/BlogCard.tsx

/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Blog } from "../data/blogs";

interface BlogCardProps {
  blog: Blog;
}

const BlogCardContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  width: 300px;
  transition: transform 0.2s, box-shadow 0.2s;

   &:hover {
    transform: translateY(-5px); /* Lifts the card slightly */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Increases shadow on hover */
  }
`;

const Category = styled.h4`
  color: #686868;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  text-align: center;
`;

const Title = styled.h3`
  font-size: 18px;
  margin: 0;
  color: #000;
`;

const Description = styled.p`
  font-size: 14px;
  color: #666;
  margin: 10px 0;
`;

const AuthorInfo = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #888;
`;

const ImgBlog = css`
    object-fit: contain;
    width: 100%;
    height: 50%;
    border-radius: 5px;
`;

const BlogCard: React.FC<BlogCardProps> = ({ blog }) => {
  return (
    <div>
    <BlogCardContainer>
      <Category>{blog.category}</Category>
      <img src={blog.img} css={ImgBlog} />
      <Title>{blog.title}</Title>
      <Description>{blog.description}</Description>
      <div css={AuthorInfo}>
        <p>{blog.author}</p>
        <span>{blog.date}</span>
      </div>
    </BlogCardContainer>
    </div>
  );
};

export default BlogCard;