/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from "@emotion/react";
import Img from '../components/Img';
import ButtonTheme from '../components/common/Button';
import TestimonialsSlider from '../components/Testimonials'
import PricingSection from '../components/Pricing';
import Loader from '../components/common/Loader';
import ProgressiveLoader from '../components/common/ProgressiveLoader';
import BlogCard from "../components/BlogCard";
import { blogs } from "../data/blogs";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from 'react-icons/fa'; // import FaArrowRight icon from react-icons
import Modal from '../components/common/Modal'; // import Modal
import SignInForm from '../components/common/Login'; // import SignInForm

interface SubHeadingProps {
  marginBottom?: string; // Allow dynamic margin-bottom as a prop
}
const HomeContainer = styled.div`
`;

// Emotion CSS styles
const heroBannerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 91px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const textContainer = css`
  max-width: 50%;
  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
`;

const headingStyle = css`
  font-size: 3.2rem;
  margin-bottom: 20px;
  margin-top: 0;
`;

const paragraphStyle = css`
  font-size: 1rem;
  margin-bottom: 30px;
`;

const imageContainer = css`
  max-width: 50%;
  width: 100%;

  img {
    width: 80%;
    height: auto;
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin-top: 20px;
    text-align: center;
  }
`;

// Emotion CSS styles Section 2
const whyBGhostSectionStyle = css`
  text-align: center;
  padding: 60px 20px;
`;

const iconGrid = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const iconBox = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30%;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

const iconStyle = css`
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
`;

const iconHeadingStyle = css`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333333;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const iconTextStyle = css`
  font-size: 1rem;
  color: #555555;
`;


// Emotion CSS styles Section 3
const howItWorksSectionStyle = css`
text-align: center;
padding: 30px 20px;
`;

// Emotion CSS styles with dynamic margin
const subHeadingStyle = (props: SubHeadingProps) => css`
  font-size: 1.8rem;
  margin-bottom: ${props.marginBottom || '10px'}; // Default to 20px if no margin provided
`;

const stepContainer = css`
display: flex;
flex-direction: column;
gap: 40px;
`;

const stepStyle = css`
display: flex;
align-items: center;
gap: 20px;
@media (max-width: 768px) {
  flex-direction: column;
  text-align: center;
}
`;

const stepReverseStyle = css`
flex-direction: row-reverse;
@media (max-width: 768px) {
  flex-direction: column;
}
`;

const imageStyle = css`
width: 100%;
height: auto;
max-width: 500px;
`;

const stepTextContainer = css`
flex: 1;
text-align: left;
@media (max-width: 768px) {
  text-align: center;
}
`;

const stepNumberStyle = css`
font-size: 2.3rem;
margin-bottom: 5px;

@media (max-width: 768px) {
  font-size: 2rem;
  margin-top: 1px;
}
`;

const stepTextStyle = css`
font-size: 1.4rem;
color: #555555;
@media (max-width: 768px) {
  font-size: 1rem;
  margin-top: 1px;
}
`;

const blogList = css`
  display: flex;
  justify-content: space-evenly; /* Fixed typo: 'ustify-content' -> 'justify-content' */
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack the items vertically on mobile */
    align-items: flex-start; /* Align items to the start of the container */
  }
`;


const Home: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // state to handle modal

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  const handleOpenModal = () => setIsModalOpen(true); // function to open modal
  const handleCloseModal = () => setIsModalOpen(false); // function to close modal


  const handleViewAll = () =>{
    navigate('/blog');
  }

  return (
    <HomeContainer>
      {loading ? <ProgressiveLoader /> : 
      <div css={heroBannerStyle}>
        <div css={textContainer}>
          <h1 css={headingStyle}>
            Elevate Your Videos <br /> with AI Face & Voice Technology
          </h1>
          <p css={paragraphStyle}>
            Experience the future of digital content with our AI-powered platform,
            where you can seamlessly transform faces and voices in videos.
          </p>
          <ButtonTheme primary borderRad='30px' background={'#0C0C0C'} color='#fff' type="submit" onClick={handleOpenModal} > Get Started For Free </ButtonTheme>
          <ButtonTheme primary borderRad='30px' background={'#7861FA'} color='#fff' > Studio 
            <span style={{ 'marginLeft': '6px' }}>
              <FaArrowRight style={{ 'transform': 'rotate(-40deg)' }} />
            </span>
          </ButtonTheme>
        </div>
        <div css={imageContainer}>
          <Img ImgName='img/Main_Image.png' alt='Main Image' />
        </div>
      </div>
    }
      {/* WHY B-Ghost Section */}
      <section css={whyBGhostSectionStyle}>
      <h2 css={headingStyle}>Why B-Ghost?</h2>
      <div css={iconGrid}>
        <div css={iconBox}>
          <Img ImgName='img/swap-1.png' alt='Icon 1' css={iconStyle} />
          <h3 css={iconHeadingStyle}>Face Swapping</h3>
          <p css={iconTextStyle}> Seamlessly swap faces in your videos with cutting-edge AI technology.</p>
        </div>
        <div css={iconBox}>
          <Img ImgName='img/mic-1.png' alt='Icon 2' css={iconStyle} />
          <h3 css={iconHeadingStyle}>Voice Changer</h3>
          <p css={iconTextStyle}> Transform voices effortlessly with advanced AI voice modulation.</p>
        </div>
        <div css={iconBox}>
          <Img ImgName='img/player-1.png' alt='Icon 2' css={iconStyle} />
          <h3 css={iconHeadingStyle}>High Quality</h3>
          <p css={iconTextStyle}> Ensure the highest quality for your video content, powered by AI.</p>
        </div>
      </div>
    </section>

    {/* SECTION 3 HOW ITS WORK */}
    <section id='howitWorks' css={howItWorksSectionStyle}>
      <h2 css={headingStyle}>How It Works?</h2>
      <p css={subHeadingStyle({ marginBottom: '40px' })}>Simple, Fast, and Fun!</p>
      
      <div css={stepContainer}>
        {/* Step 1 */}
        <div css={stepStyle}>
          <div css={imageContainer}>
            <Img ImgName='img/upload-a-video.png' alt='Select Face and Voice' css={imageStyle} />
          </div>
          <div css={stepTextContainer}>
            <h3 css={stepNumberStyle}>1.</h3>
            <h3 css={stepNumberStyle}>Upload Your Video</h3>
            <p css={stepTextStyle}>Choose a video in the app.</p>
          </div>
        </div>

        {/* Step 2 */}
        <div css={[stepStyle, stepReverseStyle]}>
         <div css={imageContainer}>
            <Img ImgName='img/uploadFace.png' alt='Select Face and Voice' css={imageStyle} />
          </div>
          <div css={stepTextContainer}>
            <h3 css={stepNumberStyle}>2. </h3>
            <h3 css={stepNumberStyle}>Select Face </h3>
            <p css={stepTextStyle}>
              Pick your new face from our library.
            </p>
          </div>
        </div>

        {/* Step 3 */}
        <div css={stepStyle}>
          <div css={imageContainer}>
          <Img ImgName='img/SelectVoice.png' alt='Select Face and Voice' css={imageStyle} />
          </div>
          <div css={stepTextContainer}>
            <h3 css={stepNumberStyle}>3. </h3>
            <h3 css={stepNumberStyle}>Pick A Voice</h3>
              <p css={stepTextStyle}>Pick your new Voice from our library.</p>
            </div>
          </div>

          {/* Step 4 */}
          <div css={[stepStyle, stepReverseStyle]}>
            <div css={imageContainer}>
              <Img ImgName='img/share.png' alt='Select Face and Voice' css={imageStyle} />
            </div>
            <div css={stepTextContainer}>
              <h3 css={stepNumberStyle}>4. </h3>
              <h3 css={stepNumberStyle}>Share with the World</h3>
              <p css={stepTextStyle}>
                Apply the changes and share your video with the world
              </p>
            </div>
          </div>
      </div>
    </section>

    {/* SECTION 4 TESTIMONIALS */}
    <section css={whyBGhostSectionStyle}>
      <p css={subHeadingStyle({})}>Testimonials</p>
      <h2 css={headingStyle}>What Our Users Say</h2>
      <TestimonialsSlider />
      </section>


      {/* SECTION 4 TESTIMONIALS */}
      <section css={whyBGhostSectionStyle}>
        <p css={subHeadingStyle({})}>Our Blogs</p>
        <h2 css={headingStyle}>Resources and Insights</h2>
        <div css={blogList}>
          {blogs.slice(0, 3).map((blog) => (
            <Link to={`/blog/${blog.title}`} key={blog.id} style={{ textDecoration: 'none' }}>
              <BlogCard blog={blog} />
            </Link>
          ))}
        </div>

        <ButtonTheme primary borderRad='30px' background={'#0C0C0C'} color='#fff' onClick={handleViewAll} >View All </ButtonTheme>

      </section>

      {/* SECTION 5 Pricing */}
    <PricingSection onSignUpClick={handleOpenModal} />
   
  {/* Modal for Sign In Form */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <SignInForm />
      </Modal>
    </HomeContainer>
  );
};

export default Home;