// src/data/blogs.ts

export interface Blog {
    id: number;
    img: string,
    category: string;
    title: string;
    description: string;
    longDescription: string;
    author: string;
    date: string;
  }
  
const longText = "\
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet vel augue ut ultricies. Mauris augue est, pharetra id lacus sit amet, semper maximus neque. Pellentesque non tincidunt ante. Nunc erat lorem, semper eget consequat sagittis, lobortis eget ligula. Duis finibus aliquet neque, sed commodo metus pretium sit amet. Mauris semper ante neque, vitae venenatis nisl fermentum sed. Vivamus ac ornare odio, non mattis arcu. Nullam felis eros, venenatis eget diam a, maximus efficitur ex. Fusce id viverra nisi. Mauris eget tellus quis elit auctor convallis at id leo. Proin sed molestie justo, in facilisis justo.\
Ut mattis magna ut quam ullamcorper, a placerat neque tempus. Aenean tempus massa non eros consectetur, nec ornare ligula volutpat. Aenean in turpis nec velit mollis varius. Etiam quis augue nec velit egestas laoreet. Quisque sollicitudin, mauris ut hendrerit ornare, ipsum ligula posuere turpis, quis lacinia mauris sapien feugiat nunc. Fusce non nulla neque. Aliquam consectetur risus sit amet ante ultrices auctor. Nunc non dictum neque. Fusce et orci in nibh mattis pellentesque in at ex. Maecenas aliquam fringilla dictum. Pellentesque in felis vehicula, auctor leo quis, sagittis diam. Donec massa nulla, posuere in fringilla id, porttitor ac felis. In at massa a erat ultrices volutpat. Aenean ut neque a orci mattis lacinia. Phasellus efficitur sem facilisis ipsum vulputate suscipit. Curabitur bibendum mattis ipsum a mollis.\
Donec aliquet turpis at consectetur tristique. Sed sed libero consequat, facilisis arcu ac, hendrerit est. Vestibulum volutpat semper ipsum id tempor. Vestibulum lobortis porttitor tortor feugiat commodo. Nulla facilisi. In vel commodo justo. Sed ultricies purus in tellus porttitor convallis. Fusce euismod, quam a scelerisque pellentesque, enim elit cursus nulla, vel dignissim magna tellus ac lectus.\
Vivamus euismod auctor pulvinar. Fusce ac placerat magna, id iaculis nunc. Mauris quis lacinia est. Etiam sed dignissim lorem. Duis dictum quam in venenatis dapibus. Duis in lacinia turpis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Suspendisse fringilla feugiat metus non eleifend. Morbi non lacus augue. Maecenas ut tortor et diam blandit elementum non ac lectus. Nam bibendum pulvinar venenatis. Nulla at elit eget quam aliquam rutrum vel sit amet nisi. Nam mollis varius elit, consequat tempus mauris vestibulum nec. Suspendisse rhoncus urna ut finibus viverra. Fusce sem tellus, euismod eu interdum non, pellentesque in lorem. Nullam sed justo mauris.\
Suspendisse malesuada enim at lacus vestibulum lobortis. Phasellus laoreet vehicula euismod. Aliquam aliquam quis erat a suscipit. Donec tellus risus, suscipit lacinia imperdiet vel, tempor porttitor est. Pellentesque laoreet venenatis dolor eget facilisis. Quisque consectetur cursus porttitor. Integer et purus vitae mauris rhoncus ornare ut quis libero. Maecenas sagittis vel leo quis scelerisque. Nulla tincidunt diam nec elit laoreet, sed tristique turpis accumsan. Suspendisse ut nibh orci. Quisque tempus finibus dignissim. "

  export const blogs: Blog[] = [
    {
      id: 1,
      category: "Design",
      img: "https://itbrief.com.au/uploads/story/2023/11/27/img-d320oqYWscvU8q8HLX0brOyX.webp",
      title: "UX review presentations",
      description: "How do you create compelling presentations that wow your colleagues and impress your managers?",
      longDescription: longText,
      author: "Olivia Rhye",
      date: "20 Jan 2022",
    },
    {
      id: 2,
      category: "Product",
      img: "https://cybersify.tech/wp-content/uploads/2024/09/Product-1024x576.png",
      title: "Migrating to Linear 101",
      description: "Linear helps streamline software projects, sprints, tasks, and bug tracking. Here’s how to get started.",
      longDescription: longText,
      author: "Phoenix Baker",
      date: "19 Jan 2022",
    },
    {
      id: 3,
      category: "Software Engineering",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnapM-UFe5ooyxvd9TE7y-5ESORDss3cpsHQ&s",
      title: "Building your API Stack",
      description: "The rise of RESTful APIs has been met by a rise in tools for creating, testing, and managing them.",
      longDescription: longText,
      author: "Lana Steiner",
      date: "18 Jan 2022",
    },
    // Additional AI blogs
    {
      id: 4,
      category: "AI",
      img: "https://itbrief.com.au/uploads/story/2023/11/27/img-d320oqYWscvU8q8HLX0brOyX.webp",
      title: "AI in Content Creation",
      description: "Explore how AI can help streamline content creation processes.",
      longDescription: longText,
      author: "Alice Smith",
      date: "15 Oct 2023",
    },
    {
      id: 5,
      category: "AI",
      img: "https://itbrief.com.au/uploads/story/2023/11/27/img-d320oqYWscvU8q8HLX0brOyX.webp",
      title: "Introduction to Generative AI",
      description: "A beginner's guide to understanding and using generative AI tools.",
      longDescription: longText,
      author: "Bob Johnson",
      date: "12 Nov 2023",
    },
    {
      id: 6,
      category: "AI",
      img: "https://itbrief.com.au/uploads/story/2023/11/27/img-d320oqYWscvU8q8HLX0brOyX.webp",
      title: "AI-Powered Video Editing",
      description: "Learn how AI tools are revolutionizing video editing.",
      longDescription: longText,
      author: "Carol White",
      date: "23 Sep 2023",
    },
    {
      id: 7,
      category: "Content Creation",
      img: "https://itbrief.com.au/uploads/story/2023/11/27/img-d320oqYWscvU8q8HLX0brOyX.webp",
      title: "Using AI to Generate Marketing Content",
      description: "Strategies for leveraging AI to create engaging marketing materials.",
      longDescription: longText,
      author: "David Green",
      date: "5 Nov 2023",
    },
    {
      id: 8,
      category: "AI",
      img: "https://itbrief.com.au/uploads/story/2023/11/27/img-d320oqYWscvU8q8HLX0brOyX.webp",
      title: "Natural Language Processing for Writers",
      description: "How NLP tools can assist writers in improving content quality.",
      longDescription: longText,
      author: "Evelyn Black",
      date: "1 Nov 2023",
    },
  ];
  