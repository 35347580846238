import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

// Keyframes for the spin animation
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Overlay for full-screen loader
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

// Spinner style with animation
const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`;

// Percentage Display
const PercentageText = styled.p`
  margin-top: 10px;
  font-size: 16px;
  color: #333;
`;

// Loader Bar Container
const LoaderBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: transparent;
  z-index: 1001;
`;

// Loader Bar
const LoaderBar = styled.div<{ progress: number }>`
  width: ${({ progress }) => progress}%;
  height: 100%;
  background-color: #3498db;
  transition: width 0.2s ease;
`;

const ProgressiveLoader: React.FC = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Simulate progressive loading
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100; // Stop at 100%
        }
        return prev + 10; // Increment by 10% per tick
      });
    }, 300); // Update every 300ms

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <>
      <LoaderBarContainer>
        <LoaderBar progress={progress} />
      </LoaderBarContainer>
      <Overlay>
        <div>
          <Spinner />
          <PercentageText>{progress}%</PercentageText>
        </div>
      </Overlay>
    </>
  );
};

export default ProgressiveLoader;
