import { css } from '@emotion/react';
import { theme } from './theme';

export const globalStyles = css`
  body {
    margin: 0;
    padding: 0;
    font-family: 'poppins' ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${theme.colors.backgroundLight};
    color: ${theme.colors.textDark};
  }
.swal2-styled {
    min-width: 100px;
}
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
.css-i7hgb6{
    position: relative !important;
    left: 50% !important;
    top: 50% !important;
    width: 150px !important;
    height: 150px !important;
    margin: -75px 0 0 -75px !important;
    border-radius: 50% !important;
    border: 3px solid transparent !important;
    border-top-color: #fff !important;
    -webkit-animation: spin 2s linear infinite !important;
    animation: spin 2s linear infinite !important;
}

.css-1x18ity{
background:rgb(0 0 0 / 81%) !important;
}

.css-i7hgb6:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #fff;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.css-i7hgb6:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #fff;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
  @keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.css-1siuc7u{
    display:none !important;
}
  

.custom-btn {
    background: #000;
    padding: 8px 20px;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    border-radius: 30px;
}

.float-right{
float: right;
}

`;