import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { FaArrowRight, FaUserAlt, FaSignOutAlt } from 'react-icons/fa'; // Updated icons
import Modal from './Modal';
import SignInForm from './Login';
import Img from '../Img';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for programmatic navigation

const HeaderContainer = styled.header`
  background-color: ${props => props.theme.colors.background};
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: transform 0.5s ease-in-out;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavLink = styled(Link)`
  color: ${props => props.theme.colors.textLight};
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const SignButton = styled.button`
  border-radius: 100px;
  border: 1px solid #414141;
  background: #fff;
  width: 150px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  line-height: 0;
  text-align: center;
  cursor: pointer;
  font-family: inherit;

  &:hover {
    background: ${props => props.theme.colors.hoverLightButton};
  }
`;

const IconWrapper = styled.span`
  margin-left: 8px;
`;

const MenuContainer = styled.div`
  border-radius: 100px;
  border: 1px solid #414141;
  background: #0C0C0C;
  padding: 1rem 2rem;
`;

const MenuLink = styled(NavLink)`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 20px;

  &:hover {
    background: #323232;
    border-radius: 15px;
  }
`;

const UserIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  &:hover > div {
    display: block;
  }
`;

const UserName = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  background: #8d66ff;
  padding: 15px;
  border-radius: 50%;
  text-align: center;
  line-height: 1;
  width: 20px;
  height: 20px;
`;

const UserMenu = styled.div`
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #0C0C0C;
  border-radius: 8px;
  padding: 10px;
  width: 250px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background: #323232;
    border-radius: 4px;
  }
`;

const UserInfo = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #323232;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserNameText = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #fff;
`;

const UserEmailText = styled.div`
  font-size: 12px;
  color: #aaa;
`;

const LogoutButton = styled(MenuItem)`
  background: transparent;
  color: #fff;
  border: none;
  text-align: left;
  cursor: pointer;

  &:hover {
    background: #323232;
  }
`;

const Header: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleLogout = () => {
    
    console.log('Logged out');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('company_name');
    sessionStorage.removeItem('First_Name');
    sessionStorage.removeItem('Last_Name');
    sessionStorage.removeItem('phoneNumber');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('P_User_Id');
    
    sessionStorage.clear();

  
    sessionStorage.clear();
    window.location.href = '/';
    setIsLoggedIn(false);


  };

  useEffect(() => {
    const storedUserName = sessionStorage.getItem('username');
    const storedUserEmail = sessionStorage.getItem('email');
    if (storedUserName && storedUserEmail) {
      setUserName(storedUserName);
      setUserEmail(storedUserEmail);
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);
  const navigate = useNavigate(); // Hook for navigation

  const handleProfileMenuClick = () => {
    navigate('/setting/profile'); // Redirect to the profile settings page
  };
  const handleProfiledashboard = () => {
    navigate('/dashboard'); // Redirect to the profile settings page
  };
  return (
    <HeaderContainer>
      <Nav>
        <NavLink to="/">
          <Img ImgName={'logos/logo_white.png'} alt="BGhost Logo" width={150} />
        </NavLink>

        <MenuContainer>
          <MenuLink to="/">Home</MenuLink>
          <MenuLink to="/about">About</MenuLink>
          <MenuLink to="/blog">Blogs</MenuLink>
          <MenuLink to="/price">Price</MenuLink>
          <MenuLink to="/community">Community</MenuLink>
        </MenuContainer>

        <div>
          {isLoggedIn ? (
            <UserIconWrapper>
              <UserName>{userName[0]}</UserName> {/* First letter of user's name */}
              <UserMenu>
                <UserInfo>
                  <FaUserAlt size={18} color="#fff" />
                  <UserDetails>
                    <UserNameText>{userName}</UserNameText>
                    <UserEmailText>{userEmail}</UserEmailText>
                  </UserDetails>
                </UserInfo>
                <MenuItem onClick={handleProfiledashboard}>
                  <FaUserAlt size={14} />
                  Dasboard
                </MenuItem>
                <MenuItem onClick={handleProfileMenuClick}>
                  <FaUserAlt size={14} />
                  Profile Menu
                </MenuItem>
                <LogoutButton onClick={handleLogout}>
                  <FaSignOutAlt size={14} />
                  Log Out
                </LogoutButton>
              </UserMenu>
            </UserIconWrapper>
          ) : (
            <SignButton onClick={handleOpenModal}>
              Sign In
              <IconWrapper>
                <FaArrowRight style={{ transform: 'rotate(-40deg)' }} />
              </IconWrapper>
            </SignButton>
          )}

          {/* Sign-in Modal */}
          <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
            <SignInForm />
          </Modal>
        </div>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
